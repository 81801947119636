import React, { useEffect, lazy, Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { LayoutSplashScreen } from "../components/MetronicSplashScreen";
import axios from "axios";

import * as actions from "../app/modules/Settings/_redux/settingsActions";
import { fetchMyProfile } from "./modules/Profiles/_redux/profileActions";
import { SubscriptionModal } from "../components/SubscriptionModal";
import Loader from "./modules/components/Loader";
import Swal from "sweetalert2";
import { fetchAllMySubscriptions } from "./modules/Profiles/_redux/subscriptions/subscriptionsActions";
import { Layout } from "../components/Layout";
import { is } from "date-fns/locale";
import Base from "./pages/Zoom/Base.js";

const ProgramPage = lazy(() => import("./modules/Programs/pages/ProgramPage"));
const CoursePage = lazy(() => import("./modules/Courses/pages/CoursePage"));
const AuthorsPage = lazy(() =>
  import("./modules/Profiles/components/AuthorsPage")
);
const CartPage = lazy(() => import("./modules/Cart/components/CartPage"));
const JobPage = lazy(() => import("./modules/Jobs/pages/JobPage"));
const MyLearningPage = lazy(() =>
  import("./modules/MyLibrary/pages/MyLibraryPage")
);
const ContactPage = lazy(() => import("./pages/Contact"));
const SupportPage = lazy(() => import("./pages/Support"));
const SupportPageV2 = lazy(() => import("./pages/SupportV2"));
const SupportPageV3 = lazy(() => import("./pages/SupportV3"));
const SupportPageV4 = lazy(() => import("./pages/SupportV4"));
const AboutPage = lazy(() => import("./pages/About"));
const GraderPage = lazy(() => import("./pages/Grader"));
const GraderPageV2 = lazy(() => import("./pages/GraderV2"));
const GraderPageV3 = lazy(() => import("./pages/GraderV3"));
const GraderPageV4 = lazy(() => import("./pages/GraderV4"));
const SupportSuccess = lazy(() => import("./pages/SupportSuccess"));
const GraderSuccess = lazy(() => import("./pages/GraderSuccess"));
const ProfilePage = lazy(() => import("./modules/Profiles/ProfilePage"));
const AlumniPage = lazy(() => import("./modules/Alumni/AlumniPage"));
const Events = lazy(() => import("./pages/EventPage"));
const LeadMgt = lazy(() => import("./pages/LeadMgt"));
const Facilitator = lazy(() => import("./pages/Facilitator"));
const EnrolmentInstructions = lazy(() =>
  import("./pages/EnrolmentInstructions")
);
const ProgramsPage = lazy(() => import("./pages/v2/programs/Page"));
const CoursesPage = lazy(() => import("./pages/v2/courses/Page"));
const TeamPage = lazy(() => import("./pages/v2/Team"));
const OpenDayPage = lazy(() => import("./pages/v2/OpenDay"));
const BlogPage = lazy(() => import("./pages/v2/blog/Page"));
const FAQPage = lazy(() => import("./pages/v2/FAQ"));
const TestimonialsPage = lazy(() => import("./pages/v2/Testimonials"));
const SalesPage = lazy(() => import("./pages/v2/Sales"));
const ExperiencePage = lazy(() => import("./pages/v2/Experience"));
const TermsPage = lazy(() => import("./pages/v2/Terms"));
const LandingPage = lazy(() => import("./pages/v2/Landing"));
const WebinarPage = lazy(() => import("./pages/v2/Webinar"));
const WebinarReg = lazy(() => import("./pages/v2/Hackathon/WebinarReg.js"));

export default function BasePage() {
  const { isAuthorized, profile, subscriptions, has_cpd } = useSelector(
    ({ auth, profiles, subscriptions }) => ({
      isAuthorized: auth.user != null,
      profile: profiles.profile,
      subscriptions: subscriptions.mySubs,
      has_cpd: subscriptions.mySubs.some((sub) => sub.is_cpd),
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuthorized) {
      dispatch(actions.fetchBusinessSettings());
      dispatch(fetchMyProfile());
    }
  }, [dispatch, isAuthorized]);
  useEffect(() => {
    if (profile.subscribed === false)
      document.getElementById("subscriptionButton").click();
  }, [profile]);
  useEffect(() => {
    if (isAuthorized)
      dispatch(fetchAllMySubscriptions({ current_cohort: true }));
  }, [dispatch, isAuthorized]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Route path="/experience" component={ExperiencePage} />
        <Route
          path="/manual"
          render={() => {
            window.location.replace("https://bit.ly/blackforceTechManual");
            return <LayoutSplashScreen />;
          }}
        />
        <Route
          path="/masterclass"
          render={() => {
            window.location.replace(
              "https://blackforce123.my.site.com/masterclass"
            );
            return <LayoutSplashScreen />;
          }}
        />
        <Route
          path="/lunchandlearn"
          render={() => {
            window.location.replace(
              "https://blackforce123.my.site.com/lunchandlearn"
            );
            return <LayoutSplashScreen />;
          }}
        />
        <Layout>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route path="/program" component={ProgramsPage} />
            <Route path="/courses" component={CoursePage} />
            {/* <Route path="/programs" component={ProgramsPage} /> */}
            <Route path="/programs" component={ProgramsPage} />
            {/* <Route path="/course" component={CoursesPage} /> */}
            <Route path="/contact" component={ContactPage} />
            <Route path="/support" component={SupportPage} />
            <Route path="/supportv2" component={SupportPageV2} />
            <Route path="/supportv3" component={SupportPageV3} />
            <Route path="/supportv4" component={SupportPageV4} />
            <Route path="/about" component={AboutPage} />
            <Route path="/support-success" component={SupportSuccess} />
            <Route path="/grader-success" component={GraderSuccess} />
            <Route path="/event" component={Events} />
            <Route path="/events" component={Events} />
            <Route path="/leadmgt" component={LeadMgt} />
            <Route path="/enrolment" component={EnrolmentInstructions} />
            {/* <Route path="/masterclass" component={Base} /> */}
            {/* <Route path="/masterclass" component={MasterClass} />
            <Route path="/lunchandlearn" component={LaunchAndLearn} /> */}
            {/* <Route path="/zoom" component={Base} /> */}
            <Route path="/zoom" component={Zoom} />
            <Route path="/zoom-technical" component={Zoom2} />
            <Route path="/officehours" component={Zoom2} />
            <Route path="/facilitators" component={Facilitator} />
            <Route path="/course" component={CoursesPage} />
            <Route path="/team" component={TeamPage} />
            <Route path="/blog" component={BlogPage} />
            <Route path="/faq" component={FAQPage} />
            <Route path="/testimonials" component={TestimonialsPage} />
            <Route path="/sales" component={SalesPage} />
            <Route path="/terms" component={TermsPage} />
            <Route path="/hackathon/register" component={WebinarReg} />
            <Route path="/hackathon" component={WebinarPage} />
            <Route path="/alumni" component={AlumniPage} />
            <Route path="/youth" component={Youth} />
            <Route path="/openday" component={OpenDayPage} />
            <Route path="/opendayevent" component={OpenDayICS} />
            {!isAuthorized ? (
              /*Redirect to `/auth` when user is not authorized*/
              // <Redirect to="/auth/login" />
              <Redirect
                to={{
                  pathname: "/auth/login",
                  search: `?redirectTo=${encodeURIComponent(
                    window.location.pathname + window.location.search
                  )}`,
                }}
              />
            ) : (
              <Switch>
                <Route path="/authors" component={AuthorsPage} />
                <Route path="/cart/:type/:id" component={CartPage} />
                <Route path="/library" component={MyLearningPage} />
                <Route path="/profile" component={ProfilePage} />
                <Route path="/graderv4" component={GraderPageV4} />
                {subscriptions.length ? (
                  <>
                    <Route path="/graderv2" component={GraderPageV2} />
                    <Route path="/graderv3" component={GraderPageV3} />
                    <Route path="/grader" component={GraderPage} />
                    <Route path="/jobs" component={JobPage} />
                  </>
                ) : (
                  <Redirect to="/programs" />
                )}
              </Switch>
            )}
            <Redirect to="/error" />
          </Switch>
        </Layout>
      </Switch>
      <button
        type="button"
        className="btn btn-primary m-2 font-weight-bolder d-none"
        data-toggle="modal"
        data-target="#SubscriptionModal"
        id="subscriptionButton"
      >
        Subscripion
      </button>
      <SubscriptionModal />
    </Suspense>
  );
}
const Zoom = () => {
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/zoom_link")
      .then((res) =>
        res.ZoomLink
          ? (window.location.href = res.ZoomLink)
          : Swal.fire({
              icon: "error",
              html: "No zoom link set",
            })
      )
      .catch((err) =>
        Swal.fire({
          icon: "error",
          html: err.message,
        })
      );
  }, []);
  return <Loader />;
};
const Zoom2 = () => {
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/zoom_link2")
      .then((res) =>
        res.ZoomLink2
          ? (window.location.href = res.ZoomLink2)
          : Swal.fire({
              icon: "error",
              html: "No zoom link set",
            })
      )
      .catch((err) =>
        Swal.fire({
          icon: "error",
          html: err.message,
        })
      );
  }, []);
  return <Loader />;
};

const MasterClass = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href =
        "https://docs.google.com/forms/d/12tkMtJYUVCoMaQhiiRfjLsD6vEkx8L4Z5ITdnZpDzK4";
    }, 2000); // 2000ms = 2 seconds

    // Cleanup timer if component unmounts
    return () => clearTimeout(timer);
  }, []);

  return <Loader />;
};
const LaunchAndLearn = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href =
        "https://docs.google.com/forms/d/1s70kSIeB0SMR9jjeZ3Gpiih3Zzh7xYMWc0zTGRURRbA";
    }, 2000); // 2000ms = 2 seconds

    // Cleanup timer if component unmounts
    return () => clearTimeout(timer);
  }, []);

  return <Loader />;
};

const Youth = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href =
        "https://docs.google.com/forms/d/1wjVFG-VbWnMNzAaEH8ctf6kqrrqsPDNfkvhNiEpodaI";
    }, 1000); // 2000ms = 2 seconds

    // Cleanup timer if component unmounts
    return () => clearTimeout(timer);
  }, []);

  return <Loader />;
};
// const OpenDay = () => {
//   useEffect(() => {
//     const timer = setTimeout(() => {
//       window.location.href =
//         "https://docs.google.com/forms/d/1VYNiYSIXeknoiJvYMfi9ntA-rmpoqUMvkwKLrEtvHMA";
//     }, 1000); // 2000ms = 2 seconds

//     // Cleanup timer if component unmounts
//     return () => clearTimeout(timer);
//   }, []);

//   return <Loader />;
// };

const OpenDayICS = () => {
  useEffect(() => {
    // Create link to download the ICS file from public directory
    const link = document.createElement("a");
    link.href = "../openday.ics"; // Assuming file is in public folder
    link.setAttribute("download", "openday.ics");
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);

    // Redirect to homepage after a short delay
    const timer = setTimeout(() => {
      window.location.href = "/";
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return <Loader />;
};
